import * as API from 'src/apis';
import ReportsDetail from './ReportsDetail';

export default function ReportsDetailContainer() {
  const dummyReport = {
    title: '令和5年度 エネルギー使用状況届出書',
    beginAt: 1727708400000,
    endAt: 1730386740000,
    gatewayName: '日東工業キャビネット（小牧・長久手）',
  } as API.Report;

  return <ReportsDetail report={dummyReport} />;
}
