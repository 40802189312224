import {
  Menu as _Menu,
  MenuItem as _MenuItem,
  MenuItemProps,
  MenuProps,
} from '@mui/material';

export function Menu({ children, ...rest }: MenuProps) {
  return (
    <_Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...rest}
    >
      {children}
    </_Menu>
  );
}

export function MenuItem({ children, ...rest }: MenuItemProps) {
  return (
    <_MenuItem
      {...rest}
      sx={{
        willChange: 'transform',
        px: 2,
        py: 0.5,
        mx: 1,
        borderRadius: 1,
        minHeight: 'auto',

        ':hover': {
          backgroundColor: 'primary.light',
        },

        ':focus-visible': {
          backgroundColor: 'primary.light',
        },
      }}
    >
      {children}
    </_MenuItem>
  );
}
