import { BaseMainContents } from 'src/domains/root/commons/layout/MainContents';
import ReportsDetailContainer from '../features/reports/ReportsDetailContainer';

export default function ReportsDetail() {
  return (
    <BaseMainContents>
      <ReportsDetailContainer />
    </BaseMainContents>
  );
}
