import React from 'react';
import { Navigate, Outlet, Routes } from 'react-router-dom';
import Initializer from 'src/utils/Initializer';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Route } from 'react-router-dom';
import BaseLayoutContainer from 'src/domains/root/commons/layout/BaseLayout';
import withRepeatedTokenExpirationBreaker from 'src/utils/withRepeatedTokenExpirationBreaker';
import FeatureFlag from 'src/utils/feature-flag/FeatureFlag';
import Home from './pages/Home';
import GatewaySensorData from './pages/GatewaySensorData';
import CurrentAlert from './pages/CurrentAlert';
import AlertHistory from './pages/AlertHistory';
import Setting from './pages/Setting';
import UnrecoverableError from './pages/errors/UnrecoverableError';
import OtherSetting from './pages/OtherSetting';
import UsersManagement from './pages/other-setting/users/Management';
import UsersInvitation from './pages/other-setting/users/Invitation';
import GatewaysNew from './pages/other-setting/gateways/New';
import GatewaysSensorData from './pages/other-setting/gateways/SensorData';
import GatewaysMacAddress from './pages/other-setting/gateways/MacAddress';
import PackagesNew from './pages/other-setting/packages/New';
import PackagesCreated from './pages/other-setting/packages/Created';
import ContractList from './pages/other-setting/contracts/List';
import UnrecoverableErrorHandler from './routes/UnrecoverableErrorHandler';
import WorkspaceRequired from './routes/WorkspaceRequired';
import OnlyNoWorkspace from './routes/OnlyNoWorkspace';
import AlertStatus from './routes/AlertStatus';
import InvalidEmailChangeIdError from './pages/errors/InvalidEmailChangeIdError';
import UsedEmailChangeIdError from './pages/errors/UsedEmailChangeIdError';
import DuplicateEmailOthersError from './pages/errors/DuplicateEmailOthersError';
import ExpiredEmailChangeIdError from './pages/errors/ExpiredEmailChangeIdError';
import JoinedWorkspaceError from './pages/errors/JoinedWorkspaceError';
import RepeatedTokenExpirationError from './pages/errors/RepeatedTokenExpirationError';
import EmergencyMaintenanceError from './pages/errors/EmergencyMaintenanceError';
import UnknownError from './pages/errors/UnknownError';
import EmailUpdate from './features/users/email-changes/Update';
import EmailUpdated from './features/users/email-changes/Updated';
import RecoverableError from './pages/errors/RecoverableError';
import AccessControl from './commons/AccessControl';
import DeleteAccount from './pages/DeleteAccount';
import DeleteAccountSuccess from './pages/DeleteAccountSuccess';
import SetupWelcome from './pages/SetupWelcome';
import SetupWorkspace from './pages/SetupWorkspace';
import SetupGateway from './pages/SetupGateway';
import HeaderOnlyLayout from './commons/layout/HeaderOnlyLayout';
import WorkspaceRedirect from './features/workspaces/WorkspaceRedirect';
import GatewaysMemos from './pages/GatewaysMemos';
import PaymentMethod from './pages/other-setting/contracts/PaymentMethod';
import PaymentMethodInvoice from './pages/other-setting/contracts/PaymentMethodInvoice';
import AdminOnly from './commons/AdminOnly';
import {
  PostPaymentsFailedUpdateContractBecauseNpInvoiceActivatingErrorPage,
  PostPaymentsFailedUpdateContractBecauseNpInvoiceActivatingErrorPageRoute,
} from './features/contracts/ContractTableContainer';
import {
  PutWorkspacesPaymentsInvoicesActivatingGoneErrorPage,
  PutWorkspacesPaymentsInvoicesActivatingGoneErrorPageRoute,
} from './features/contracts/payment-method/EnableInvoice';
import Reports from './pages/Reports';
import ReportsDetail from './pages/ReportsDetail';
import ReportsNew from './pages/ReportsNew';
import {
  PostWorkspacesReportsErrorPage,
  PostWorkspacesReportsErrorPageRoute,
} from './features/reports/ReportsMakeNewContainer';

const ProtectedInitializer = withRepeatedTokenExpirationBreaker(
  withAuthenticationRequired(Initializer),
);

const _Routes: React.FC = () => {
  return (
    <Routes>
      <Route path="errors/*">
        <Route
          path="permission-denied"
          element={
            <UnrecoverableError messageId="common.errors.permission_denied" />
          }
        />
        <Route
          path="admin-only"
          element={<UnrecoverableError messageId="common.errors.admin_only" />}
        />
        <Route
          path="workspace-not-found"
          element={
            <UnrecoverableError messageId="common.errors.workspace_not_found" />
          }
        />
        <Route
          path="account-is-not-deletable"
          element={
            <UnrecoverableError
              messageId="common.errors.account_is_not_deletable"
              buttonTextId="common.errors.account_is_not_deletable_button_text"
              buttonHref="/delete-account"
            />
          }
        />
        <Route path="joined-workspace" element={<JoinedWorkspaceError />} />
        <Route
          path="repeated-token-expiration"
          element={<RepeatedTokenExpirationError />}
        />
        <Route
          path="emergency-maintenance"
          element={<EmergencyMaintenanceError />}
        />
        <Route
          path="unauthorized"
          element={
            <UnrecoverableError
              messageId="common.errors.unauthorized"
              buttonTextId="common.buttons.toLoginPage"
            />
          }
        />
        <Route path="profile/*">
          <Route
            path="invalid-email-change-id"
            element={<InvalidEmailChangeIdError />}
          />
          <Route
            path="used-email-change-id"
            element={<UsedEmailChangeIdError />}
          />
          <Route
            path="duplicate-email"
            element={<DuplicateEmailOthersError />}
          />
          <Route
            path="expired-email-change-id"
            element={<ExpiredEmailChangeIdError />}
          />
        </Route>
        <Route path="unknown-error" element={<UnknownError />} />
      </Route>
      <Route
        path={
          PostPaymentsFailedUpdateContractBecauseNpInvoiceActivatingErrorPageRoute
        }
        element={
          <PostPaymentsFailedUpdateContractBecauseNpInvoiceActivatingErrorPage />
        }
      />
      <Route
        path={PutWorkspacesPaymentsInvoicesActivatingGoneErrorPageRoute}
        element={<PutWorkspacesPaymentsInvoicesActivatingGoneErrorPage />}
      />
      <Route
        path={PostWorkspacesReportsErrorPageRoute}
        element={<PostWorkspacesReportsErrorPage />}
      />
      <Route
        path="profile/*"
        element={
          <ProtectedInitializer>
            <Outlet />
          </ProtectedInitializer>
        }
      >
        <Route
          path="update"
          element={
            <EmailUpdate
              redirectTo={{
                invalid_email_change_id:
                  '/errors/profile/invalid-email-change-id',
                used_email_change_id: '/errors/profile/used-email-change-id',
                duplicate_email_others: '/errors/profile/duplicate-email',
                expired_email_change_id:
                  '/errors/profile/expired-email-change-id',
              }}
            >
              {(updated) =>
                updated ? (
                  <Navigate to="/profile/updated" />
                ) : (
                  <RecoverableError messageId="features.users.emailChanges.update.errors.unknown_error" />
                )
              }
            </EmailUpdate>
          }
        />
        <Route path="updated" element={<EmailUpdated />} />
      </Route>
      <Route
        path="workspaces/:workspaceId/settings/others/contracts/payment"
        element={
          <ProtectedInitializer>
            <WorkspaceRedirect redirectTo="/settings/others/contracts" />
          </ProtectedInitializer>
        }
      />
      <Route
        path="workspaces/:workspaceId/settings/others/contracts/payment-method"
        element={
          <ProtectedInitializer>
            <WorkspaceRedirect redirectTo="/settings/others/contracts/payment-method" />
          </ProtectedInitializer>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedInitializer>
            <UnrecoverableErrorHandler
              redirectTo={{
                permission_denied: '/errors/permission-denied',
                admin_only: '/errors/admin-only',
                workspace_not_found: '/errors/workspace-not-found',
                emergency_maintenance: '/errors/emergency-maintenance',
                unknown_error: '/errors/unknown-error',
              }}
            >
              <WorkspaceRequired>
                {(workspaces) =>
                  workspaces.length === 0 ? (
                    <Navigate to="/setup/welcome" />
                  ) : (
                    <BaseLayoutContainer>
                      <AlertStatus>
                        <Outlet />
                      </AlertStatus>
                    </BaseLayoutContainer>
                  )
                }
              </WorkspaceRequired>
            </UnrecoverableErrorHandler>
          </ProtectedInitializer>
        }
      >
        <Route index element={<Home />} />
        <Route path="gateways/:physicalId/memos" element={<GatewaysMemos />} />
        <Route
          path="gateways/:physicalId/chart"
          element={<GatewaySensorData />}
        />
        <Route path="current-alert" element={<CurrentAlert />} />
        <Route path="alert-history" element={<AlertHistory />} />
        <Route
          path="reports/*"
          element={
            <FeatureFlag flag="workspace.report">
              <Outlet />
            </FeatureFlag>
          }
        >
          <Route path=":reportId" element={<ReportsDetail />} />
          <Route path="new" element={<ReportsNew />} />
          <Route index element={<Reports />} />
        </Route>
        <Route path="settings/*">
          <Route path="sensor" element={<Setting />} />
          <Route path="others/*">
            <Route index element={<OtherSetting />} />
            <Route path="users/*">
              <Route
                path="management"
                element={
                  <AccessControl
                    permit={(permissions) =>
                      permissions.includes('workspace.user.read')
                    }
                  >
                    {(permitted) =>
                      permitted ? (
                        <UsersManagement />
                      ) : (
                        <Navigate to="/errors/admin-only" />
                      )
                    }
                  </AccessControl>
                }
              />
              <Route
                path="invitation"
                element={
                  <AccessControl
                    permit={(permissions) =>
                      permissions.includes('invitation.create') ||
                      permissions.includes('invitation.read') ||
                      permissions.includes('invitation.delete')
                    }
                  >
                    {(permitted) =>
                      permitted ? (
                        <UsersInvitation />
                      ) : (
                        <Navigate to="/errors/admin-only" />
                      )
                    }
                  </AccessControl>
                }
              />
            </Route>
            <Route path="gateways/*">
              <Route
                path="new"
                element={
                  <AccessControl
                    permit={(permissions) =>
                      permissions.includes('cabiotpack.create')
                    }
                  >
                    {(permitted) =>
                      permitted ? (
                        <GatewaysNew />
                      ) : (
                        <Navigate to="/errors/admin-only" />
                      )
                    }
                  </AccessControl>
                }
              />
              <Route
                path="sensor-data"
                element={
                  <AccessControl
                    permit={(permissions) =>
                      permissions.includes('cabiotpack.data.delete')
                    }
                  >
                    {(permitted) =>
                      permitted ? (
                        <GatewaysSensorData />
                      ) : (
                        <Navigate to="/errors/admin-only" />
                      )
                    }
                  </AccessControl>
                }
              />
              <Route path="mac-address" element={<GatewaysMacAddress />} />
            </Route>
            <Route path="packages/*">
              <Route
                path="new"
                element={
                  <AccessControl
                    permit={(permissions) =>
                      permissions.includes('workspace.create')
                    }
                  >
                    {(permitted) =>
                      permitted ? (
                        <PackagesNew />
                      ) : (
                        <Navigate to="/errors/admin-only" />
                      )
                    }
                  </AccessControl>
                }
              />
              <Route path="created" element={<PackagesCreated />} />
            </Route>

            <Route path="contracts/*">
              <Route
                path="payment-method/*"
                element={
                  <FeatureFlag flag="contract.payment-method">
                    <AdminOnly />
                  </FeatureFlag>
                }
              >
                <Route path="invoice" element={<PaymentMethodInvoice />} />
                <Route index element={<PaymentMethod />} />
              </Route>

              <Route index element={<ContractList />} />
            </Route>
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Route>
      <Route
        path="setup"
        element={
          <ProtectedInitializer>
            <UnrecoverableErrorHandler
              redirectTo={{
                joined_workspace: '/errors/joined-workspace',
                emergency_maintenance: '/errors/emergency-maintenance',
              }}
            >
              <OnlyNoWorkspace redirectTo="/">
                <HeaderOnlyLayout>
                  <Outlet />
                </HeaderOnlyLayout>
              </OnlyNoWorkspace>
            </UnrecoverableErrorHandler>
          </ProtectedInitializer>
        }
      >
        <Route path="welcome" element={<SetupWelcome />} />
        <Route path="workspace" element={<SetupWorkspace />} />
        <Route
          path="gateway"
          element={
            <SetupGateway ifNoWorkspaceName={<Navigate to="welcome" />} />
          }
        />
        <Route path="*" element={<Navigate to="welcome" />} />
      </Route>
      <Route path="delete-account">
        <Route
          index
          element={
            <ProtectedInitializer>
              <WorkspaceRequired reagreement={false}>
                {() => <DeleteAccount />}
              </WorkspaceRequired>
            </ProtectedInitializer>
          }
        />
        <Route path="success" element={<DeleteAccountSuccess />} />
      </Route>
    </Routes>
  );
};

export default _Routes;
