import { Box, IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  BreadcrumbTitleV3,
  BreadcrumbsV3,
} from 'src/domains/root/commons/breadcrumbs';
import { toYmdSlashHm } from 'src/utils/time';
import { HeadTitle, Text } from 'src/shared/ui';
import { Menu, MenuItem } from 'src/domains/root/commons/Menu';

import * as API from 'src/apis';
import { MenuRounded } from '@mui/icons-material';
import { useState } from 'react';

export default function ReportsDetail({ report }: { report?: API.Report }) {
  return (
    <Box display="flex" flexDirection="column" rowGap={3.5}>
      <Breadcrumb />

      <ReportsDetailPaper>
        <HeadMenu disabled={!report} />
        <ReportOverview report={report} />
      </ReportsDetailPaper>
    </Box>
  );
}

function Breadcrumb() {
  const intl = useIntl();

  return (
    <Box pl={{ xs: 2, sm: 0 }}>
      <BreadcrumbsV3>
        <Link to="/reports">
          <BreadcrumbTitleV3 color="primary">
            {intl.formatMessage({
              id: 'pages.Reports.title',
            })}
          </BreadcrumbTitleV3>
        </Link>
        <BreadcrumbTitleV3>
          {intl.formatMessage({
            id: 'pages.Reports.Detail.title',
          })}
        </BreadcrumbTitleV3>
      </BreadcrumbsV3>
    </Box>
  );
}

function ReportsDetailPaper({ children }: { children: React.ReactNode }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={3}
      bgcolor="white"
      p={3}
      border={{ xs: 'none', sm: '1px solid #828282' }}
      borderRadius={1}
    >
      {children}
    </Box>
  );
}

function HeadMenu({ disabled }: { disabled?: boolean }) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleUpdateTitle = () => {
    setAnchorEl(null);
  };

  return (
    <Box position="relative">
      <HeadTitle>
        {intl.formatMessage({
          id: 'pages.Reports.Detail.headTitle',
        })}
      </HeadTitle>
      <IconButton
        disabled={disabled}
        aria-label="menu"
        onClick={handleOpenMenu}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          mt: -15 / 8,
          mr: -1.5,
          opacity: disabled ? '40%' : 'unset',
        }}
      >
        <MenuRounded sx={{ color: 'primary.main', fontSize: 26.67 }} />
      </IconButton>

      <Menu open={openMenu} anchorEl={anchorEl} onClose={handleCloseMenu}>
        <MenuItem onClick={handleUpdateTitle}>
          {intl.formatMessage({
            id: 'pages.Reports.Detail.menu.updateTitle',
          })}
        </MenuItem>
      </Menu>
    </Box>
  );
}

function ReportOverview({ report }: { report?: API.Report }) {
  const intl = useIntl();

  if (!report) {
    return (
      <Text textAlign="center">
        {intl.formatMessage({
          id: 'pages.Reports.Detail.overview.noData',
        })}
      </Text>
    );
  }

  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Text variant="Title_A">{report.title}</Text>
      <Box
        display="flex"
        columnGap={3.5}
        rowGap={1}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box display="flex" columnGap={1} flex="none">
          <Text variant="Basic_A">
            {intl.formatMessage({
              id: 'pages.Reports.Detail.overview.targetPeriod',
            })}
          </Text>
          <Text>
            {toYmdSlashHm(DateTime.fromMillis(report.beginAt))}〜
            {toYmdSlashHm(DateTime.fromMillis(report.endAt))}
          </Text>
        </Box>
        <Box display="flex" columnGap={1}>
          <Text variant="Basic_A" flex="none">
            {intl.formatMessage({
              id: 'pages.Reports.Detail.overview.gatewayName',
            })}
          </Text>
          <Text flex="0 1 auto">{report.gatewayName}</Text>
        </Box>
      </Box>
    </Box>
  );
}
